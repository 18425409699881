import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import GlassContainer from '../components/GlassContainer';
import ContactForm from '../components/ContactForm';
import { Carousel } from 'react-bootstrap';
import VideoSection from '../components/VideoSection'; 

const Home = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImgSrc, setLightboxImgSrc] = useState('');

  const openLightbox = (imgSrc) => {
    setLightboxImgSrc(imgSrc);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImgSrc('');
  };

  return (
    <div className="home">
      {/* Video Section */}
      <VideoSection />
      {/* About Us Section */}
      <section id="about" className="about-section">

          <GlassContainer>
            <h1>Welcome to Skincare by Marzi</h1>
            <img
            src="/images/marzi.jpeg"
            alt="About Us"
            className="about-image img-fluid"
          />
          <h3>Marzi Salehi</h3>
          <h5>Certified Medical Aesthetician</h5>
            <p>
              Marzi is a certified medical aesthetician with over 20 years of
              experience. She is committed to helping you achieve your best skin.
            </p>
            <a href="/appointment" className="btn btn-primary mt-3">
              Book Appointment
            </a>
          </GlassContainer>
      </section>


      {/* Services Section */}
      <section id="services" className="services-section">
        <GlassContainer>

        <h2>Our Services</h2>
        <hr className="divider" />

        
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <Link to="/consultation-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Consultation</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4" >
            <Link to="/facial-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Facial Treatments</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/microdermabrasion-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Microdermabrasion</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/microneedling-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Microneedling</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/chemical-peel-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Chemical Peel</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/dermaplaning-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Dermaplaning</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>
        </div>
      </GlassContainer>

      </section>


      {/* Other Sections */}
      <GlassContainer>
        <section id="appointment" className="appointment-section">
          <h2>Book Your Appointment</h2>
          <div className="appointment-content text-center">
            <p>
              Ready to get started? Book your appointment now and experience the
              best skincare treatments.
            </p>
            <a href="/appointment" className="btn btn-primary">
              Book Appointment
            </a>
          </div>
        </section>
      </GlassContainer>


      {/* Testimonials Section */}
      <section id="testimonials" className="testimonials-section my-5">
        <h2 className="text-center mb-4">What Our Clients Say</h2>
        <GlassContainer>
          <div className="container">
            <Carousel>
              <Carousel.Item>
                <blockquote className="blockquote text-center">
                  <p>"Best facial ever! My skin has never looked better!"</p>
                  <footer className="blockquote-footer">Sarah, Edmonton</footer>
                </blockquote>
              </Carousel.Item>
              <Carousel.Item>
                <blockquote className="blockquote text-center">
                  <p>"Amazing experience, truly relaxing and professional."</p>
                  <footer className="blockquote-footer">Anna, Edmonton</footer>
                </blockquote>
              </Carousel.Item>
              <Carousel.Item>
                <blockquote className="blockquote text-center">
                  <p>"Marzi is simply the best! My skin feels brand new."</p>
                  <footer className="blockquote-footer">Emily, Toronto</footer>
                </blockquote>
              </Carousel.Item>
            </Carousel>
          </div>
        </GlassContainer>
      </section>

      {/* Gallery Section */}
      <section id="gallery" className="gallery-section my-5">
        <GlassContainer>
          <h2 className="text-center mb-4">Our Work</h2>
          <hr className="divider grid"/>
          <div className="container">
            <div className="row">
              {Array.from({ length: 9 }, (_, i) => (
                <div key={i} className="col-12 col-md-4 mx-auto">
                  <div className="gallery-item">
                    <img
                      src={`/images/work${i + 1}.png`}
                      alt={`Gallery ${i + 1}`}
                      className="img-fluid rounded"
                      onClick={() => openLightbox(`/images/work${i + 1}.png`)}
                    />
                    <div className="logo-overlay">
                      <img
                        src="/images/logo2.png"
                        alt="Website Logo"
                        className="logo-center"
                        onClick={() => openLightbox(`/images/work${i + 1}.png`)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </GlassContainer>
      </section>

      {/* Lightbox */}
      {lightboxOpen && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="close">&times;</span>
          <img className="lightbox-content" src={lightboxImgSrc} alt="Expanded view" />
        </div>
      )}

      {/* Contact Us Section */}
      <section id="contact" className="contact-section glass">
        <h2>Contact Us</h2>
        <hr className="divider" />
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
