import './Footer.css'; 

function Footer() {

  return (
    <footer className="footer">
      <p>© {new Date().getFullYear()} All Rights Reserved.</p>
      <p>
        <a href="/privacy-policy">Privacy Policy</a> | 
        <a href="/terms-of-service">Terms of Service</a>
      </p>
      <a href="/appointment" className="footer-link">Book Appointment</a> {/* Appointment link in footer */}

    </footer>
  );
}

export default Footer;
