import React, { useEffect, useRef } from 'react';
import './VideoSection.css';

const VideoSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Ensures autoplay compatibility on mobile
      videoRef.current.play().catch(() => {
        videoRef.current.controls = true; // Show controls if autoplay fails
      });
    }
  }, []);

  const scrollToNextSection = () => {
    const nextSection = document.querySelector(".about-section");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="video-section">
      <div className="video-container">
        <video
          ref={videoRef}
          className="video-fluid"
          controls
          autoPlay
          loop
          playsInline
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
        >
          <source src="/videos/video11.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Arrow Button */}
        <button className="scroll-down-arrow" onClick={scrollToNextSection}>
          ↓
        </button>
      </div>
    </section>
  );
};

export default VideoSection;
