import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Chemical peels are a powerful way to reveal brighter, smoother, and more youthful skin. By applying a chemical solution to the skin, we exfoliate and remove dead skin cells, allowing fresh, healthy skin to emerge. Our peels are effective in treating fine lines, wrinkles, sun damage, acne scars, and uneven pigmentation. We tailor the intensity of the peel to your skin type and goals, ensuring the best possible results with minimal downtime.";
  const priceList = [
    { service: 'Perfect Chemical Peel  (60 minutes)', price: '$299' },
  ];

  return (
    <GlassContainer>
      <div className="service-page container">
        <h1>Chemical Peel</h1>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <p>{description}</p>
          </div>
          
          <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
            <img
              src={"/images/perfect1.jpg"}
              alt={"Dermaplaning"}
              className="img-fluid"
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
        
        {/* Price List */}
        <div className="price-list">
          <h2>Price List</h2>
          <ul className="flex-wrap">
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>

          {/* Appointment Button */}
          <div className="text-center mt-4 appointment-button">
            <a href='/appointment' className="btn btn-primary">
              Book Your Appointment
            </a>
          </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
