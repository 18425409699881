import React from 'react';

const TermsOfService = () => (
  <div className="container my-5">
    <h2>Terms of Service</h2>
    <p> <b>Effective Date: October 2024</b></p>
<span>By accessing or using Skincare by Marzi's website and services, you agree to the following Terms of Service.</span>

<li>Services: Skincare by Marzi offers skincare treatments, including facials, 
    chemical peels, and microdermabrasion, customized to individual needs. 
    All treatments are conducted by certified professionals.</li>

<li>Booking and Payment: Appointments can be booked via our website. 
    Payments are due upon completion of services, 
    and cancellation must be done at least 24 hours in advance to avoid charges.</li>

<li>Liability: Skincare by Marzi is not responsible for any adverse reactions or 
    results due to information not disclosed by clients during consultation or treatment.</li>

<li>Changes to Services: We reserve the right to modify or discontinue any services at any time without notice.</li>
<p>By accessing or using Skincare by Marzi's website and services, you agree to the following Terms of Service.</p>
    <p>
      We reserve the right to modify or discontinue any services at any time without prior notice. 
      Users are responsible for keeping their booking and contact information up-to-date.
    </p>
    <p>
      All services are provided by certified professionals, 
      and results may vary depending on individual skin types and conditions. 
      Refunds are not provided for completed services.
    </p>
  </div>
);

export default TermsOfService;
