import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home'; // Import your Home component
import Appointment from './pages/Appointment'; // Import your Appointment component
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the new Privacy Policy page
import TermsOfService from './pages/TermsOfService'; // Import the new Terms of Service page
import FacialService from './pages/FacialService';
import ConsultationService from './pages/ConsultationService';
import ChemicalPeelService from './pages/ChemicalPeelService';
import DermaplaningService from './pages/DermaplaningService';
import MicrodermabrasionService from './pages/MicrodermabrasionService';
import MicroneedlingService from './pages/MicroneedlingService';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <div className="main-content">
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<Home />} /> {/* Home Route */}
          <Route path="/appointment" element={<Appointment />} /> {/* Appointment Route */}
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-of-service" element={<TermsOfService/>} />
          <Route path="/consultation-service" element={<ConsultationService/>} />
          <Route path="facial-service" element={<FacialService/>} />
          <Route path="/microdermabrasion-service" element={<MicrodermabrasionService/>} />
          <Route path="/microneedling-service" element={<MicroneedlingService/>} />
          <Route path="/chemical-peel-service" element={<ChemicalPeelService/>} />
          <Route path="/dermaplaning-service" element={<DermaplaningService/>} />

        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
