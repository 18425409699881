import React from 'react';

const PrivacyPolicy = () => (
  <div className="container my-5">
    <h2>Privacy Policy</h2>

    <p> <b>Effective Date: October 2024</b></p>

    <p>At Skincare by Marzi, we are committed to protecting your privacy.</p>
        

    <p>This Privacy Policy outlines how we collect, use, and safeguard your personal 
        information when you visit our website or book a service.</p>

    <ul><b>Information We Collect:</b></ul>

    <li>Personal Data: Name, contact details, and any other information provided when booking appointments.</li>
    <li>Usage Data: Information on how you interact with our website, including IP address and browsing activity.</li>
<br/>

    <ul><b>How We Use Your Information:</b></ul>

    <li>To manage appointments, provide services, and send notifications.</li>
    <li>To improve user experience and website functionality.</li>
    <li>Data Protection: We implement secure measures to protect your personal 
        information from unauthorized access, disclosure, or alteration.</li>

    <li>Third-Party Services: We may share your data with trusted third-party providers 
        such as booking systems or payment processors to facilitate our services.</li>

    <p>Your Rights: You have the right to access, update, or delete your personal information.
         Contact us at <a href="mailto:marzi@marskincare.ca">marzi@marskincare.ca</a> for any privacy-related concerns.</p>

  </div>
);

export default PrivacyPolicy;
