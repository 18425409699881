import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Microdermabrasion is an advanced exfoliation technique designed to improve skin texture, tone, and clarity. Using a specialized tool, we gently remove the outermost layer of dead skin cells, revealing smoother, more radiant skin underneath. This treatment is ideal for reducing fine lines, acne scars, and uneven pigmentation, leaving your skin soft, smooth, and glowing. Regular sessions can help maintain a more youthful complexion by encouraging the growth of new, healthy skin cells.";
  const priceList = [
    { service: 'Micro-dermabrasion (60 minutes)', price: '$110' },
  ];

  return (
    <GlassContainer>
      <div className="service-page container">
        <h1>Microdermabrasion</h1>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <p>{description}</p>
          </div>
          
          <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
            <img
              src={"/images/microdermabrasion1.jpg"}
              alt={"Dermaplaning"}
              className="img-fluid"
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
        
        {/* Price List */}
        <div className="price-list">
          <h2>Price List</h2>
          <ul className="flex-wrap">
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>

          {/* Appointment Button */}
          <div className="text-center mt-4 appointment-button">
            <a href='/appointment' className="btn btn-primary">
              Book Your Appointment
            </a>
          </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
